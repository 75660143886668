import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Slides from "./Slideshow";

import sunsetImage from "./sunset.jpg";

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore-next-line
function importAll(r): string[] {
  return r.keys().map(r);
}

const allImages = importAll(
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  require.context("../public/photos/cheryl", false, /\.(webp|png|jpe?g)$/)
);

const duration = Math.round(allImages.length / 30) * 1000;

ReactDOM.render(
  <React.StrictMode>
    <Slides
      imgUrls={allImages}
      alt="Cheryl"
      endImgUrl={sunsetImage}
      randomize={true}
      duration={duration}
    />
  </React.StrictMode>,
  document.getElementById("root")
);
